var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-play-component"},[_c('ValidationObserver',{ref:"addPlayObserver",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('a-row',{attrs:{"gutter":[12, 12],"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":24}},[_c('span',{staticClass:"form-title"},[_vm._v(_vm._s(_vm.labels.form.addPlays))])])],1),_c('a-row',{style:({ margin: 0 }),attrs:{"gutter":[12, 12],"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"xs":12,"sm":12,"md":10,"lg":10}},[_c('Input',{directives:[{name:"themask",rawName:"v-themask",value:(_vm.isSuperpale ? ['##-##'] : ['##', '##-##', '##-##-##']),expression:"isSuperpale ? ['##-##'] : ['##', '##-##', '##-##-##']"}],ref:`numbers`,attrs:{"placeholder":_vm.isSuperpale
              ? _vm.labels.form.superPale.placeholder
              : _vm.labels.form.numbers.placeholder,"inputmode":"numeric","vid":`numbers`,"label":_vm.labels.form.numbers.label,"rules":_vm.isSuperpale
              ? 'required|validPlayNumber|validPale'
              : 'required|validPlayNumber|validPlay',"max-length":_vm.isSuperpale ? 5 : 8,"formItem":{
            style: {
              marginBottom: 0,
            },
          }},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.focusAmount.apply(null, arguments)}},model:{value:(_vm.form.numbers),callback:function ($$v) {_vm.$set(_vm.form, "numbers", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.numbers"}})],1),_c('a-col',{attrs:{"xs":12,"sm":12,"md":10,"lg":10}},[_c('Input',{ref:"amount",attrs:{"placeholder":_vm.labels.form.amount.placeholder,"type":"number","vid":"amount","label":_vm.labels.form.amount.label,"max":99999999999,"min":0,"rules":'required',"precision":0,"formItem":{
            style: {
              marginBottom: 0,
            },
          }},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":4,"lg":4}},[_c('div',{staticClass:"action-buttons"},[_c('a-button',{staticClass:"btn-add",attrs:{"type":"primary","block":""},on:{"click":_vm.onSubmit}},[(!_vm.isMobile)?_c('span',[_vm._v(_vm._s(_vm.labels.form.add))]):_vm._e(),_c('a-icon',{attrs:{"type":"plus"}})],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }