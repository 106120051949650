<template>
  <div class="add-play-component">
    <ValidationObserver
      ref="addPlayObserver"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <a-row :gutter="[12, 12]" type="flex" justify="start">
        <a-col :span="24">
          <span class="form-title">{{ labels.form.addPlays }}</span>
        </a-col>
      </a-row>
      <a-row
        :gutter="[12, 12]"
        type="flex"
        justify="start"
        :style="{ margin: 0 }"
      >
        <a-col :xs="12" :sm="12" :md="10" :lg="10">
          <Input
            v-model.trim="form.numbers"
            :placeholder="
              isSuperpale
                ? labels.form.superPale.placeholder
                : labels.form.numbers.placeholder
            "
            inputmode="numeric"
            :vid="`numbers`"
            :ref="`numbers`"
            :label="labels.form.numbers.label"
            :rules="
              isSuperpale
                ? 'required|validPlayNumber|validPale'
                : 'required|validPlayNumber|validPlay'
            "
            v-themask="isSuperpale ? ['##-##'] : ['##', '##-##', '##-##-##']"
            :max-length="isSuperpale ? 5 : 8"
            :formItem="{
              style: {
                marginBottom: 0,
              },
            }"
            v-on:keyup.enter.native="focusAmount"
          />
        </a-col>
        <a-col :xs="12" :sm="12" :md="10" :lg="10">
          <Input
            v-model="form.amount"
            :placeholder="labels.form.amount.placeholder"
            type="number"
            vid="amount"
            ref="amount"
            :label="labels.form.amount.label"
            :max="99999999999"
            :min="0"
            :rules="'required'"
            :precision="0"
            v-on:keyup.enter.native="onSubmit"
            :formItem="{
              style: {
                marginBottom: 0,
              },
            }"
          />
        </a-col>
        <a-col :xs="24" :sm="24" :md="4" :lg="4">
          <div class="action-buttons">
            <a-button type="primary" class="btn-add" @click="onSubmit" block>
              <span v-if="!isMobile">{{ labels.form.add }}</span>
              <a-icon type="plus" />
            </a-button>
          </div>
        </a-col>
      </a-row>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Input from "./core/VeeValidateForm/Input.vue";
import * as _ from "lodash";
import { mask } from "vue-the-mask";
import { nextTick } from "vue";
import { PLAY_TYPES } from "../utils/utils";

const initialState = {
  numbers: "",
  amount: undefined,
};

export default {
  name: "AddPlayComponent",
  components: {
    ValidationObserver,
    Input,
  },
  directives: { themask: mask },
  emits: ["submit"],
  props: {
    defaultData: {
      type: Object,
      default: () => ({}),
    },
    labels: {
      type: Object,
      default: () => ({}),
    },
    isSuperpale: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: _.cloneDeep(initialState),
  }),
  created() {
    if (!_.isEmpty(this.defaultData)) {
      Object.assign(this.form, this.defaultData);
    }
  },
  methods: {
    playType(numbers) {
      const numbersArray = numbers.split("-");
      if (numbersArray.length === 2) {
        return PLAY_TYPES.PALE;
      } else if (numbersArray.length === 3) {
        return PLAY_TYPES.TRIPLET;
      } else {
        return PLAY_TYPES.QUINIELA;
      }
    },
    onSubmit() {
      this.$refs.addPlayObserver.validate().then((success) => {
        if (success) {
          this.$emit("submit", {
            ..._.cloneDeep(this.form),
            type: this.isSuperpale
              ? PLAY_TYPES.SUPERPALE
              : this.playType(this.form.numbers),
          });
          this.$refs.addPlayObserver.reset();
          this.form = _.cloneDeep(initialState);
          this.focusNumbers();
        }
      });
    },
    focusNumbers() {
      nextTick(() => {
        this.$refs.numbers.$el.querySelector("input").focus();
      });
    },
    focusAmount() {
      nextTick(() => {
        this.$refs.amount.$el.querySelector("input").focus();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.add-play-component {
  padding: 15px 15px 20px 15px !important;
  background-color: color(c-secondary);
  border: 1px solid color(c-secondary);
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  .form-title {
    font-size: 18px;
    font-weight: 600;
    color: color(--white);
    margin-bottom: 10px;
    display: block;
  }
  .action-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
    padding-bottom: 5px;
    .btn-add {
      border-color: color(c-hover);
      background-color: color(c-primary);
      color: color(c-hover);
      &:hover {
        border-color: color(c-hover);
        background-color: color(c-primary);
        color: color(c-hover);
      }
    }
  }
}
</style>
