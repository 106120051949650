// Event Bus
const EventBus = new Vue();
import Vue from "vue";

export const ListEvents = {
  reload_table: "CORE_EVENT_RELOAD_TABLE",
  roload_cart_plays: "CORE_EVENT_RELOAD_CART_PLAYS",
  reset_recaptcha: "CORE_EVENT_RESET_RECAPTCHA",
  open_add_play_modal: "CORE_EVENT_OPEN_ADD_PLAY_MODAL",
  close_add_play_modal: "CORE_EVENT_CLOSE_ADD_CLOSE_MODAL",
  open_payment_modal: "CORE_EVENT_OPEN_PAYMENT_MODAL",
  close_payment_modal: "CORE_EVENT_CLOSE_PAYMENT_MODAL",
  open_referral_qr_modal: "CORE_EVENT_OPEN_REFERRAL_QR_MODAL",
  close_referral_qr_modal: "CORE_EVENT_CLOSE_REFERRAL_QR_MODAL",
  open_referral_rules_modal: "CORE_EVENT_OPEN_REFERRAL_RULES_MODAL",
  close_referral_rules_modal: "CORE_EVENT_CLOSE_REFERRAL_RULES_MODAL",
  open_all_my_referrals_modal: "CORE_EVENT_OPEN_ALL_MY_REFERRALS_MODAL",
  close_all_my_referrals_modal: "CORE_EVENT_CLOSE_ALL_MY_REFERRALS_MODAL",
  open_rebuy_ticket_modal: "CORE_EVENT_OPEN_REBUY_TICKET_MODAL",
  close_rebuy_ticket_modal: "CORE_EVENT_CLOSE_REBUY_TICKET_MODAL",
};

export default EventBus;
