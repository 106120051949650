<template>
  <a-modal
    v-if="show"
    v-model="show"
    :footer="null"
    :width="width"
    @cancel="close"
    :closable="!submitting"
    class="payment-modal"
    :maskClosable="false"
  >
    <template slot="title">
      <span class="title-text">
        <font-awesome-icon class="anticon" :icon="['far', 'money-bill-alt']" />
        {{ labels.payment.title }}
      </span>
    </template>

    <a-row
      type="flex"
      justify="center"
      :gutter="[24, 24]"
      class="payment-modal-content"
    >
      <a-col :xs="24" :sm="24" :md="18" :lg="18">
        <a-table
          v-if="dataSource.length"
          :dataSource="dataSource"
          :pagination="false"
          :columns="columns"
          :rowKey="'key'"
          :size="'small'"
          :bordered="false"
          :class="'payment-modal-table'"
          :rowClassName="(row) => `table-row `"
          :expandIcon="expandIcon"
          :expandRowByClick="false"
          :defaultExpandAllRows="true"
        />
      </a-col>
      <a-col :xs="24" :sm="24" :md="6" :lg="6">
        <a-card :border="true" class="payment-modal-total">
          <a-button
            type="primary"
            class="payment-modal-total-button-pay"
            @click="handlePayTicket"
            :disabled="submitting"
            :loading="submitting"
            block
          >
            {{ labels.payment.payTicket }}
          </a-button>
          <a-divider :style="{ marginTop: 10, marginBottom: 10 }" />
          <div class="payment-modal-total-container">
            <span class="payment-modal-total-label"
              >{{ labels.payment.totalTicket }}
            </span>
            <span class="payment-modal-total-text">{{
              total | formatMoney("$ ", 2, ",", ".")
            }}</span>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import EventBus, { ListEvents } from "../event";
import labels from "@/utils/labels";
import accounting from "accounting";
import { mapState } from "vuex";
import _ from "lodash";
import { generateUniqueID } from "../utils/utils";
export default {
  name: "BuyPaymentModal",
  emits: ["pay", "close"],
  props: {
    labels: {
      type: Object,
      default: () => labels,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    EventBus.$on(ListEvents.open_payment_modal, this.open);
    EventBus.$on(ListEvents.close_payment_modal, this.close);
  },
  beforeDestroy() {
    EventBus.$off(ListEvents.open_payment_modal);
    EventBus.$off(ListEvents.close_payment_modal);
  },
  computed: {
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 768;
    },
    width() {
      return this.screenWidth < 992 ? "96%" : 992;
    },
    columns() {
      return [
        {
          title: this.labels.table.play,
          dataIndex: "type",
          key: "type",
          width: "70px",
          customRender: (text, record) => {
            if (record?.isLottery) {
              const lotteryName = record.secondLottery?.id
                ? `${record.lottery.name} - ${record.secondLottery.name}`
                : record.lottery.name;
              return {
                children: <span class="text-hover">{lotteryName}</span>,
                attrs: {
                  colSpan: 4,
                },
              };
            }
            return this.$options.filters.ucfirst(text);
          },
        },
        {
          title: this.labels.table.numbers,
          dataIndex: "numbers",
          key: "numbers",
          width: "60px",
          align: "center",
          customRender: (numbers, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return numbers;
          },
        },
        {
          title: this.labels.table.amount,
          dataIndex: "amount",
          key: "amount",
          width: "60px",
          align: "right",
          customRender: (amount, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return accounting.formatNumber(amount, 2, ",", ".");
          },
        },
      ];
    },
  },
  methods: {
    uuid() {
      return generateUniqueID();
    },
    close() {
      this.$emit("close");
      this.show = false;
    },
    open() {
      if (_.cloneDeep(this.dataSource).length) this.show = true;
    },
    async handlePayTicket() {
      try {
        const data = _.cloneDeep(this.dataSource).reduce((acc, curr) => {
          return [...acc, ...curr.children];
        }, []);
        this.$emit("pay", data);
      } catch (error) {
        console.error(error);
      }
    },
    expandIcon({ expandable }) {
      return expandable ? "" : " ";
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.payment-modal {
  .ant-modal-content {
    background-color: color(c-secondary);
    border-radius: 0;
    border: 1px solid color(c-hover-wait);
    box-shadow: 0 1px 3px 3px color(c-hover-wait);
  }
  .ant-modal-header {
    background-color: transparent;
    border-color: color(c-hover);
    .title-text {
      color: color(--white);
      font-size: 18px;
      .anticon {
        color: color(c-hover);
      }
    }
  }
  &-total {
    color: color(--white);
    font-size: 14px;
    user-select: none;
    background-color: transparent;
    &-button-pay {
      background-color: color(c-hover);
      border-color: color(c-hover-light);
      border-radius: 0;
      color: color(c-primary);
      font-weight: 600;
      &:hover,
      &:focus,
      &:active {
        background-color: color(c-hover);
        border-color: color(c-hover-light);
        color: color(c-primary);
      }
    }
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-label {
      font-weight: 600;
      margin-right: 5px;
    }
    &-text {
      font-weight: 600;
      border-bottom: 1px solid color(c-primary);
    }
  }
  .ant-table {
    &:hover {
      transition: 0.5s ease;
      background: unset;
    }
    tr.ant-table-row:hover {
      transition: 0.5s ease;
      background: rgba(0, 0, 0, 0.25) !important;
    }
    tr:nth-child(2n) {
      transition: 0.5s ease;
      background: rgba(0, 0, 0, 0.2) !important;
    }
    tr.table-row-disabled,
    tr.table-row-disabled:hover {
      color: color(c-primary);
      background: color(danger-light) !important;
      font-weight: 600;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      transition: 0.5s ease;
      background: none !important;
    }
    &-tbody,
    &-thead {
      color: color(--white) !important;
      tr {
        th {
          color: color(--white) !important;
        }
      }
    }
    &-footer {
      background: transparent;
    }
  }
  .ant-modal-close {
    color: color(--white) !important;
    &:hover {
      color: color(c-hover) !important;
    }
  }
}
.success-payment-modal {
  .ant-modal-content {
    background-color: color(c-secondary) !important;
    box-shadow: 0 1px 3px 3px color(c-hover-wait);
    .ant-modal-confirm-title {
      color: color(--white) !important;
    }
    .ant-modal-confirm-content {
      color: color(--white) !important;
    }
    .ant-modal-confirm-btns {
      .ant-btn {
        background-color: color(c-hover);
        color: color(c-primary) !important;
        border-color: color(c-hover);
        &:hover,
        &:focus,
        &:active {
          background-color: color(c-hover);
        }
        &.ant-btn-primary {
          background-color: color(c-secondary);
          color: color(c-hover) !important;
          border-color: color(c-hover);
          &:hover {
            background-color: color(c-hover);
            color: color(c-primary) !important;
            border-color: color(c-hover);
          }
        }
      }
    }
  }
}
</style>
