<template>
  <a-layout class="buy">
    <TitleBreadcrumb />
    <div class="content-base container">
      <a-row type="flex" justify="center">
        <a-col class="column-default" :xs="24" :sm="24" :md="24" :lg="18">
          <div class="action-button" ref="actions">
            <span>
              <router-link
                :to="{ name: 'ticket-list' }"
                class="ant-btn ant-btn-default action-button-ticket-list"
              >
                {{ labels.ticketList }}
              </router-link>
            </span>
            <div class="action-button-date">
              <span class="action-button-date-label">{{
                labels.form.date.label
              }}</span>
              <span class="action-button-date-text">{{ form.date }}</span>
            </div>
          </div>
        </a-col>
      </a-row>

      <a-row type="flex" justify="center">
        <a-col :xs="24" :sm="24" :md="24" :lg="18">
          <ValidationObserver
            ref="buyObserver"
            tag="form"
            @submit.prevent="handleSubmit"
          >
            <a-row :gutter="[16, 16]">
              <a-col :span="24">
                <SelectPagination
                  v-model="form.lotteries"
                  ref="lotteries"
                  :fetchService="fetchLotteries"
                  :label="labels.form.lotteries.label"
                  labelKey="name"
                  valueKey="id"
                  mode="multiple"
                  :placeholder="labels.form.lotteries.placeholder"
                  size="large"
                  class="select-lotteries"
                  rules="required"
                  :disabledDefaultFetchOptions="false"
                  :disabledSortBy="true"
                  :showSearch="false"
                  @focus="scrollIntoView($refs.actions)"
                >
                  <template #renderOption="{ data }">
                    <div
                      :style="{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                      }"
                    >
                      <span class="avatar" :style="{ width: '50px' }">
                        <a-avatar
                          :style="{
                            backgroundColor: '#3a3a3a',
                            verticalAlign: 'middle',
                          }"
                          v-bind="data.logo ? { src: data.logo } : {}"
                          >{{ data.abbreviated }}</a-avatar
                        >
                      </span>
                      <span class="text-container" :style="{ width: '100%' }">
                        <div
                          :style="{
                            margin: 0,
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }"
                        >
                          <span>{{ data.name }}</span>
                          <countdown-component
                            :time="
                              $options.filters.hourToMilliseconds(
                                data.today_end_time
                              )
                            "
                            :title="labels.form.lotteries.timeLeft"
                            class="text-muted"
                          >
                            <template v-slot="{ hours, minutes, seconds }">
                              <a-icon
                                type="clock-circle"
                                :class="{
                                  'text-danger': hours === 0 && minutes < 30,
                                }"
                                :style="{ marginRight: '5px' }"
                              />
                              <span>
                                {{ hours | countDownFormat }}:{{
                                  minutes | countDownFormat
                                }}:{{ seconds | countDownFormat }}
                              </span>
                            </template>
                          </countdown-component>
                        </div>
                        <small class="text-muted">{{
                          labels.lotteryCLoseTimeLabel(data.today_end_time)
                        }}</small>
                      </span>
                    </div>
                  </template>
                </SelectPagination>
              </a-col>
            </a-row>

            <a-row :gutter="[24, 24]" type="flex" justify="start">
              <a-col :span="24">
                <span class="action-is-mix">
                  <a-switch
                    v-model="form.mix"
                    :style="{ marginRight: '10px' }"
                    class="switch-mix"
                  />
                  {{ labels.form.mix.label }}
                </span>
              </a-col>
              <a-col :span="24" v-if="showSuperpale">
                <span class="action-is-superpale">
                  <a-switch
                    v-model="form.isSuperpale"
                    :style="{ marginRight: '10px' }"
                    class="switch-superpale"
                  />
                  {{ labels.form.isSuperpale.label }}
                </span>
              </a-col>
            </a-row>
          </ValidationObserver>

          <a-row :gutter="[24, 24]" type="flex" justify="start">
            <a-col :span="24">
              <AddPlayComponent
                v-if="!form.mix"
                :isSuperpale="form.isSuperpale"
                :isMobile="isMobile"
                :labels="labels"
                @submit="handleAddPlay"
              />
              <AddMixComponent
                v-else
                :isMobile="isMobile"
                :labels="labels"
                :isSuperpale="form.isSuperpale"
                @submit="handleAddMixesPlays"
              />
            </a-col>
          </a-row>

          <a-row
            v-if="!form.mix"
            :gutter="[24, 24]"
            type="flex"
            justify="start"
          >
            <a-col :span="24" :style="{ textAlign: 'right' }">
              <a-button
                type="default"
                :loading="submitting"
                :disabled="submitting || !total"
                class="action-button-clear"
                ghost
                @click="handleCleanPlays"
              >
                {{ labels.form.clear }}
              </a-button>
              <a-button
                type="primary"
                :loading="submitting"
                :disabled="submitting || !total"
                class="action-button-pay"
                @click="handleSubmit"
                :title="total ? labels.form.pay : labels.form.clearError"
              >
                <a-icon type="check-circle" />
                {{ labels.form.pay }}
              </a-button>
            </a-col>
          </a-row>

          <a-row
            v-if="dataSource.length"
            :gutter="[24, 24]"
            type="flex"
            justify="start"
          >
            <a-col :span="24" :style="{ textAlign: 'right' }">
              <a-table
                v-if="dataSource.length"
                :dataSource="dataSource"
                :pagination="false"
                :columns="columns"
                :rowKey="'key'"
                :size="'small'"
                :bordered="false"
                :class="'cart-component-table'"
                :expandIcon="expandIcon"
                :rowClassName="(row) => `table-row`"
                :expandRowByClick="false"
                :expandedRowKeys="dataSource.map((item) => item.key)"
              />

              <!-- TOTALS CARD -->
              <a-card
                v-if="dataSource.length"
                class="totals-card"
                :style="{ marginTop: '20px' }"
                :bodyStyle="{ padding: '10px 15px' }"
                size="small"
              >
                <div class="totals">
                  <div class="totals-item">
                    <span class="totals-item-label">{{
                      labels.table.total
                    }}</span>
                    <span class="totals-item-value">{{
                      total | formatNumber(2, ",", ".")
                    }}</span>
                  </div>
                </div>
              </a-card>
            </a-col>
          </a-row>
        </a-col>
      </a-row>

      <!-- PAYMENT MODAL -->
      <PaymentModal
        v-if="canBuy"
        :dataSource="dataSource"
        :total="total"
        :submitting="submitting"
        :labels="labels"
        @pay="handlePayment"
      />

      <!-- SELECT LOTTERIES BY SHARED PLAY MODAL -->
      <ReBuyTicketModal
        :icon="sharedData ? `share-alt` : `reload`"
        :title="
          sharedData
            ? labels.rebuyTicketModal.sharedTitle
            : labels.rebuyTicketModal.rebuyTitle
        "
        :defaultData="sharedData || rebuyData || {}"
        :labels="labels"
        :columns="columns"
        @cancel="redirectToBuy"
        @submit="handleRebuyTicket"
      />
    </div>
  </a-layout>
</template>
<script>
import loadingMixin from "@/mixins/loadingMixin";
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb";
import labels from "@/utils/labels";
import {
  DATE_FORMAT,
  generateUniqueID,
  numbersCombinations,
  pageTitle,
  PLAY_TYPES,
} from "../../utils/utils";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import SelectPagination from "@/components/core/SelectPagination/index.vue";
import CountdownComponent from "@/components/CountdownComponent.vue";
import AddPlayComponent from "../../components/AddPlayComponent.vue";
import AddMixComponent from "../../components/AddMixComponent.vue";
import * as _ from "lodash";
import accounting from "accounting";
import PaymentModal from "@/components/BuyPaymentModal.vue";
import ReBuyTicketModal from "@/components/ReBuyTicketModal.vue";
import EventBus, { ListEvents } from "../../event";
import { nextTick } from "vue";

const initialState = {
  date: moment().format(DATE_FORMAT.MOMENT_DATE),
  lotteries: [],
  isSuperpale: false,
  mix: false,
  details: [],
  sharedBy: null,
  sharedTicket: null,
};

export default {
  components: {
    TitleBreadcrumb,
    ValidationObserver,
    SelectPagination,
    AddPlayComponent,
    AddMixComponent,
    PaymentModal,
    ReBuyTicketModal,
    CountdownComponent,
  },
  mixins: [loadingMixin],
  name: "Buy",
  metaInfo: {
    title: pageTitle(labels.playNow.title),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.buy.title,
      },
    ],
  },
  data() {
    return {
      labels: { ...labels.buy },
      form: _.cloneDeep(initialState),
      submitting: false,
      sharedData: null,
      rebuyData: null,
    };
  },
  filters: {
    hourToMilliseconds(value) {
      const date = moment(value, DATE_FORMAT.MOMENT_TIME);
      return date.diff(moment(), "milliseconds", true);
    },
    countDownFormat(value) {
      if (value < 0) {
        return "--";
      }
      return value.toString().padStart(2, "0");
    },
  },
  computed: {
    ...mapGetters({
      loggedUser: "clientAuth/getLoggedUser",
    }),
    total() {
      return _.sumBy(_.cloneDeep(this.form.details), (play) =>
        Number(play.amount)
      );
    },
    canBuy() {
      return this.loggedUser?.balance >= this.total;
    },
    showSuperpale() {
      return this.form.lotteries.length >= 2;
    },
    isMobile() {
      return this.screenWidth <= 767;
    },
    columns() {
      return [
        {
          title: this.labels.table.play,
          dataIndex: "type",
          key: "type",
          width: "80px",
          customRender: (text, record) => {
            if (record?.isLottery) {
              const lotteryName = record.secondLottery?.id
                ? `${record.lottery.name} - ${record.secondLottery.name}`
                : record.lottery.name;
              return {
                children: <span class="text-hover">{lotteryName}</span>,
                attrs: {
                  colSpan: 4,
                },
              };
            }
            return this.$options.filters.ucfirst(text);
          },
        },
        {
          title: this.labels.table.numbers,
          dataIndex: "numbers",
          key: "numbers",
          width: "60px",
          align: "center",
          customRender: (numbers, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return numbers;
          },
        },
        {
          title: this.labels.table.amount,
          dataIndex: "amount",
          key: "amount",
          width: "60px",
          align: "right",
          customRender: (amount, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return accounting.formatNumber(amount, 2, ",", ".");
          },
        },
        {
          title: "",
          dataIndex: "action",
          key: "action",
          width: "20px",
          align: "center",
          customRender: (_, record) => {
            return record.isLottery ? (
              {
                attrs: {
                  colSpan: 0,
                },
              }
            ) : (
              <div>
                <a-tooltip
                  overlayClassName={"custom-tooltip-danger"}
                  title={this.labels.table.action.remove}
                  placement={"right"}
                >
                  <a-button
                    disabled={this.deleting}
                    type="link"
                    size="small"
                    class="btn-remove"
                    onClick={() => this.handleRemove(record)}
                    icon="delete"
                    style={{ color: "#f5222d" }}
                  />
                </a-tooltip>
              </div>
            );
          },
        },
      ];
    },
    dataSource() {
      return Object.values(
        _.cloneDeep(this.form.details).reduce((acc, val) => {
          const lottery = val.lottery;
          const isSuperpale = val.type === PLAY_TYPES.SUPERPALE;
          const secondLottery = val.secondLottery || null;
          const key = isSuperpale
            ? `${lottery.id}-${secondLottery.id}`
            : lottery.id;
          if (!acc[key]) {
            acc[key] = {
              key: this.uuid(),
              lottery,
              ...(isSuperpale ? { secondLottery } : {}),
              isLottery: true,
              children: [],
            };
          }
          acc[key].children.push(val);
          return acc;
        }, {})
      );
    },
  },
  methods: {
    ...mapActions("buy", ["fetchLotteries", "processTicket"]),
    scrollIntoView(ref) {
      if (ref) {
        if (ref.$el)
          ref.$el.scrollIntoView({ behavior: "smooth", block: "start" });
        else ref.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    uuid() {
      return generateUniqueID();
    },
    expandIcon({ expandable }) {
      return expandable ? "" : " ";
    },
    countRecordByLottery(lottery, secondLottery = null) {
      if (secondLottery?.id) {
        return _.cloneDeep(this.form.details).filter(
          (detail) =>
            detail.lottery.id === lottery.id &&
            detail.secondLottery?.id &&
            detail.secondLottery.id === secondLottery.id
        ).length;
      }
      return _.cloneDeep(this.form.details).filter(
        (detail) => detail.lottery.id === lottery.id && !detail.secondLottery
      ).length;
    },
    groupedDatails(data = []) {
      return data.reduce((acc, val) => {
        const combinations = numbersCombinations(val.numbers);
        const foundIndex = acc.findIndex((item) => {
          if (
            !(
              item.type === val.type &&
              item.lottery.id === val.lottery.id &&
              combinations.includes(item.numbers)
            )
          ) {
            return false;
          }

          if (
            val.secondLottery &&
            !(item.secondLottery.id === val.secondLottery.id)
          ) {
            return false;
          }

          return true;
        });

        if (foundIndex !== -1) {
          acc[foundIndex].amount += val.amount;
        } else {
          acc.push(val);
        }

        return acc;
      }, []);
    },
    async handleSubmit() {
      try {
        const isValid = await this.$refs.buyObserver.validate();
        if (isValid) {
          if (this.canBuy) {
            EventBus.$emit(ListEvents.open_payment_modal);
          } else {
            this.$notification.error({
              message: this.labels.payError,
              description: this.labels.insufficientBalance,
            });
          }
        }
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    async handleAddMixesPlays(data) {
      try {
        const isvalid = await this.$refs.buyObserver.validate();
        if (!isvalid && !this.form.lotteries.length) {
          this.$notification.error({
            message: this.labels.form.lotteries.label,
            description: this.labels.form.lotteries.required,
            onClose: () => {
              this.$refs.lotteries.$el.querySelector("input").focus();
            },
          });
          return;
        }

        if (isvalid) {
          const details = [
            ..._.cloneDeep(this.form.lotteries).reduce((acc, lottery) => {
              const dataDetails = _.cloneDeep(data)
                .filter(({ type }) => type !== PLAY_TYPES.SUPERPALE)
                .map((item) => ({
                  ...item,
                  numbers: this.normalizeNumbers(item.numbers),
                  key: this.uuid(),
                  lottery: {
                    id: lottery.id,
                    name: lottery.name,
                    abbreviated: lottery.abbreviated,
                    today_end_time: lottery.today_end_time,
                  },
                }));
              return [...acc, ...dataDetails];
            }, []),
          ];

          // MIX MIX LOTTERIES FOR SUPERPALE
          if (this.form.lotteries.length > 1 && this.form.isSuperpale) {
            const mixesLotteries = _.cloneDeep(this.form.lotteries).reduce(
              (acc, lottery) => {
                this.form.lotteries.forEach((secondLottery) => {
                  if (lottery.id !== secondLottery.id) {
                    const found = acc.find(
                      (item) =>
                        (item.lottery.id === lottery.id &&
                          item.secondLottery.id === secondLottery.id) ||
                        (item.lottery.id === secondLottery.id &&
                          item.secondLottery.id === lottery.id)
                    );
                    if (!found) {
                      acc.push({
                        lottery: {
                          id: lottery.id,
                          name: lottery.name,
                          abbreviated: lottery.abbreviated,
                          today_end_time: lottery.today_end_time,
                        },
                        secondLottery: {
                          id: secondLottery.id,
                          name: secondLottery.name,
                          abbreviated: secondLottery.abbreviated,
                          today_end_time: secondLottery.today_end_time,
                        },
                        key: this.uuid(),
                      });
                    }
                  }
                });
                return acc;
              },
              []
            );

            // ADD PLAYS
            mixesLotteries.forEach((item) => {
              _.cloneDeep(data)
                .filter(({ type }) =>
                  [PLAY_TYPES.PALE, PLAY_TYPES.SUPERPALE].includes(type)
                )
                .forEach((play) => {
                  const amount = play?.amountSuperpale || play?.amount;
                  delete play.amountSuperpale;
                  details.push({
                    ...play,
                    ...item,
                    numbers: this.normalizeNumbers(play.numbers),
                    amount,
                    key: this.uuid(),
                    type: PLAY_TYPES.SUPERPALE,
                  });
                });
            });
          }

          this.form.details = this.groupedDatails([
            ...this.form.details,
            ...details,
          ]);

          // SET MIX TO FALSE
          this.form.mix = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async handleAddPlay(data) {
      try {
        const isvalid = await this.$refs.buyObserver.validate();
        if (!isvalid || !this.form.lotteries.length) {
          this.$notification.error({
            message: this.labels.form.lotteries.label,
            description: this.labels.form.lotteries.required,
          });
          return;
        }

        const lotteryCount = this.form.lotteries.length;
        let details = [];

        if (this.form.isSuperpale) {
          if (lotteryCount < 2) {
            this.$notification.error({
              message: this.labels.form.lotteries.label,
              description: this.labels.form.lotteries.min(2),
            });
            return;
          }

          // LOTTERIES MIX
          this.form.lotteries.forEach((lottery, index) => {
            this.form.lotteries.forEach((secondLottery, index2) => {
              const found = details.find(
                (item) =>
                  (item.lottery.id === lottery.id &&
                    item.secondLottery.id === secondLottery.id) ||
                  (item.lottery.id === secondLottery.id &&
                    item.secondLottery.id === lottery.id)
              );
              if (!found && index !== index2) {
                details.push({
                  lottery: lottery,
                  secondLottery: secondLottery,
                });
              }
            });
          });

          // GENERATE PLAYS
          details = details.map((item) => ({
            ...data,
            numbers: this.normalizeNumbers(data.numbers),
            lottery: {
              id: item.lottery.id,
              name: item.lottery.name,
              abbreviated: item.lottery.abbreviated,
              today_end_time: item.lottery.today_end_time,
            },
            secondLottery: {
              id: item.secondLottery.id,
              name: item.secondLottery.name,
              abbreviated: item.secondLottery.abbreviated,
              today_end_time: item.secondLottery.today_end_time,
            },
          }));
        } else {
          // MIX PLAYS
          this.form.lotteries.forEach((lottery) => {
            const found = details.find(
              (item) =>
                !item.secondLottery &&
                this.normalizeNumbers(item.numbers) ===
                  this.normalizeNumbers(data.numbers) &&
                item.type === data.type &&
                item.lottery.id === lottery.id
            );
            if (!found) {
              details.push({
                ...data,
                numbers: this.normalizeNumbers(data.numbers),
                lottery: {
                  id: lottery.id,
                  name: lottery.name,
                  abbreviated: lottery.abbreviated,
                  today_end_time: lottery.today_end_time,
                },
              });
            }
          });
        }

        // ADD PLAYS IF NOT EXIST
        this.form.details = this.groupedDatails([
          ...this.form.details,
          ...details.map((item) => ({
            ...item,
            key: this.uuid(),
          })),
        ]);
      } catch (error) {
        console.error(error);
      }
    },
    async handleRemove(record) {
      const details = _.cloneDeep(this.form.details);
      const index = details.findIndex(
        (item) =>
          item.lottery.id === record.lottery.id &&
          item.numbers === record.numbers &&
          item.type === record.type &&
          item.secondLottery?.id === record.secondLottery?.id
      );
      if (index !== -1) {
        details.splice(index, 1);
        this.form.details = details;
      }
    },
    handleCleanPlays() {
      this.form.details = [];
    },
    async handlePayment(formData) {
      try {
        this.submitting = true;
        const { message, data } = await this.processTicket({
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
          details: _.cloneDeep(formData),
          total: this.total,
          sharedBy: this.form.sharedBy,
          sharedTicket: this.form.sharedTicket,
        });
        this.$confirm({
          title: this.labels.payment.success || message,
          centered: false,
          class: "success-payment-modal",
          // JSX support
          content: (
            <div style="display:block;width:100%;">
              <span style="font-weight:bold;font-size:16px;">
                {this.labels.payment.ticketLabel}
              </span>
              <span style="font-weight:bold;font-size:16px;">
                {data.number}
              </span>
            </div>
          ),
          cancelText: this.labels.success.cancelText,
          okText: this.labels.success.okText,
          okType: "primary",
          autoFocusButton: "cancel",
          onCancel: async () => {
            // GET USER BALANCE
            await this.$store.dispatch("clientAuth/checkSession");
            EventBus.$emit(ListEvents.close_payment_modal);
            this.cleanData();
          },
          onOk: async () => {
            // GET USER BALANCE
            await this.$store.dispatch("clientAuth/checkSession");
            EventBus.$emit(ListEvents.close_payment_modal);
            this.cleanData();
            this.$router.push({
              name: "ticket-view",
              params: { code: data.number },
            });
          },
        });
      } catch (error) {
        if (error.isAxiosError && [401, 403].includes(error.response.status)) {
          EventBus.$emit(ListEvents.close_payment_modal);
          this.$router.push({ name: "login" });
        } else if (error.isAxiosError && error.response.status === 400) {
          this.$notification["error"]({
            message: error?.response?.data?.message || labels.formError,
            description: (h) => {
              return h(
                "ul",
                error.response?.data?.additional_errors?.map((error) => {
                  return h("li", error);
                })
              );
            },
          });
        } else {
          this.$notification["error"]({
            message:
              error?.response?.data?.message ||
              error?.message ||
              labels.unknownServerError,
          });
        }
      } finally {
        this.submitting = false;
      }
    },
    cleanData() {
      this.form = _.cloneDeep(initialState);
      this.$refs.buyObserver.reset();
    },
    openRebuyTicketModal() {
      nextTick(() => {
        EventBus.$emit(ListEvents.open_rebuy_ticket_modal);
      });
    },
    processQuery(query, shared = false) {
      const secret = process.env.VUE_APP_CRYTO_KEY;
      if (query && secret) {
        let data = null;
        try {
          data = JSON.parse(
            window.atob(
              this.$CryptoJS.AES.decrypt(
                query.replace(/\s/g, "+"),
                secret
              )?.toString(this.$CryptoJS.enc.Utf8)
            )
          );
        } catch (error) {
          console.error(error);
        }
        if (data?.details?.length) {
          if (shared) {
            this.sharedData = data;
          } else {
            this.rebuyData = data;
          }
          this.openRebuyTicketModal();
        }
      }
    },
    redirectToBuy() {
      this.$router.push({ name: "buy" });
    },
    handleRebuyTicket(data) {
      Object.assign(this.form, {
        lotteries: data.lotteries,
        sharedBy: data.sharedBy,
        sharedTicket: data.sharedTicket,
        details: data.details,
      });
    },
  },
  watch: {
    "form.lotteries": {
      handler(newVal) {
        if (newVal.length === 1) {
          this.form.isSuperpale = false;
        }
      },
      deep: true,
    },
    "$route.query.rebuy": {
      handler: function (value, oldValue) {
        if (value && value !== oldValue) {
          this.processQuery(value);
        }
      },
      immediate: true,
    },
    "$route.query.shared": {
      handler: function (value, oldValue) {
        if (value && value !== oldValue) {
          this.processQuery(value, true);
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.buy {
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  max-width: 100% !important;
  .content-base {
    padding-top: 60px;
    @media screen and (max-width: $screen-mobile) {
      max-width: 100% !important;
    }
    .action-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      &-date {
        color: color(--white);
        font-size: 14px;
        user-select: none;
        &-label {
          font-weight: 600;
          margin-right: 5px;
        }
        &-text {
          font-weight: 400;
          border-bottom: 1px solid color(--white);
        }
      }
      &-ticket-list {
        color: color(c-hover);
        border-color: color(c-hover);
        background-color: color(c-primary);
        border-radius: 0;
        font-weight: 600;
      }
      &-clear {
        margin-top: 1.5rem;
        color: color(c-border);
        border-color: color(c-border);
        &:active,
        &:focus,
        &:hover {
          color: color(danger);
          border-color: color(danger);
          background-color: color(c-primary);
        }
      }
      &-pay {
        margin-top: 1.5rem;
        margin-left: 10px;
        color: color(c-hover);
        border-color: color(c-hover);
        background-color: color(c-primary);
        &:active,
        &:focus,
        &:hover {
          color: color(c-primary);
          border-color: color(c-hover);
          background-color: color(c-hover);
        }
      }
      @media screen and (max-width: $screen-mobile) {
        flex-direction: column-reverse;
        align-items: flex-start;
        row-gap: 15px;
      }
    }
    .totals-card {
      background-color: color(c-secondary);
      border: 1px solid color(c-border);
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      .totals {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &-item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &-label {
            font-size: 16px;
            font-weight: 600;
            color: color(--white);
            margin-right: 5px;
          }
          &-value {
            font-size: 16px;
            font-weight: 600;
            color: color(--white);
          }
        }
      }
    }
  }
  ::v-deep .ant-select-selection__choice__content {
    .avatar {
      display: none;
    }
    .text-container {
      width: 100%;
      .text-muted {
        display: none;
      }
    }
  }
  ::v-deep .ant-form-item-label label {
    font-weight: 500;
    font-size: 18px;
    color: color(--white) !important;
  }
  ::v-deep .ant-select-lg .ant-select-selection--multiple {
    background-color: color(c-secondary);
    .ant-select-selection__choice {
      background-color: color(c-primary);
      border-color: color(muted-light) !important;
      color: color(--white);
      .anticon-close {
        color: color(--white);
      }
    }
  }

  .action-is-mix,
  .action-is-superpale {
    color: color(--white);
    font-size: 16px;
    font-weight: 500;
    user-select: none;
    .ant-switch {
      background-color: color(muted);
      border-color: color(muted);
      &-checked {
        background-color: color(c-hover);
        border-color: color(c-hover);
      }
    }
  }
}
</style>
<style lang="scss">
@import "~@/assets/scss/variable";
.ant-select-dropdown {
  background-color: color(c-secondary) !important;
  .ant-select-dropdown-menu-item {
    color: color(--white) !important;
    &-selected,
    &-active {
      background-color: color(c-primary) !important;
    }
    &-selected {
      .anticon-check {
        color: color(--white) !important;
      }
    }
  }
  .text-danger {
    color: color(danger) !important;
  }
}

.ant-table {
  &:hover {
    transition: 0.5s ease;
    background: unset;
  }
  tr.ant-table-row:hover {
    transition: 0.5s ease;
    background: rgba(0, 0, 0, 0.25) !important;
  }
  tr:nth-child(2n) {
    transition: 0.5s ease;
    background: rgba(0, 0, 0, 0.2) !important;
  }
  tr.table-row-disabled,
  tr.table-row-disabled:hover {
    color: color(c-primary);
    background: color(danger-light) !important;
    font-weight: 600;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    transition: 0.5s ease;
    background: none !important;
  }
  &-tbody,
  &-thead {
    color: color(--white) !important;
    tr {
      th {
        color: color(--white) !important;
      }
    }
  }
  &-footer {
    background: transparent;
  }
  @media screen and (max-width: $screen-mobile) {
    &-small {
      border: none;
    }
    &-body {
      margin: 0 1px;
    }
  }
}

.success-payment-modal {
  .ant-modal-content {
    background-color: color(c-secondary) !important;
    box-shadow: 0 1px 3px 3px color(c-hover-wait);
    .ant-modal-confirm-title {
      color: color(--white) !important;
    }
    .ant-modal-confirm-content {
      color: color(--white) !important;
    }
    .ant-modal-confirm-btns {
      .ant-btn {
        background-color: color(c-hover);
        color: color(c-primary) !important;
        border-color: color(c-hover);
        &:hover,
        &:focus,
        &:active {
          background-color: color(c-hover);
        }
        &.ant-btn-primary {
          background-color: color(c-secondary);
          color: color(c-hover) !important;
          border-color: color(c-hover);
          &:hover {
            background-color: color(c-hover);
            color: color(c-primary) !important;
            border-color: color(c-hover);
          }
        }
      }
    }
  }
}

.ant-select {
  color: color(--white) !important;
}
</style>
