<template>
  <div class="add-mix-component">
    <ValidationObserver
      ref="addMixObserver"
      tag="form"
      @submit.prevent="addNumber"
    >
      <a-row :gutter="[12, 12]" type="flex" justify="start">
        <a-col :span="24">
          <span class="form-title">{{ labels.form.mixTitle }}</span>
        </a-col>
      </a-row>

      <a-row :gutter="[12, 12]" type="flex" justify="start">
        <a-col
          :xs="12"
          :sm="12"
          :md="isSuperpale ? 4 : 5"
          :lg="isSuperpale ? 4 : 5"
        >
          <Input
            v-model.trim="form.numbers"
            :placeholder="labels.form.number.placeholder"
            :vid="`numbers`"
            :ref="`numbers`"
            :label="labels.form.number.label"
            :rules="'required|validPlayNumber|validPlay'"
            v-themask="['##']"
            :max-length="2"
            :formItem="{
              style: {
                marginBottom: 0,
              },
            }"
            v-on:keyup.enter.native="addNumber"
            @input="(value) => value.length === 2 && addNumber()"
          />
        </a-col>
        <a-col v-if="isMobile" :span="12" />
        <a-col
          :xs="8"
          :sm="8"
          :md="isSuperpale ? 4 : 5"
          :lg="isSuperpale ? 4 : 5"
        >
          <Input
            v-model="form.quinielaAmount"
            :placeholder="labels.form.quinielaAmount.placeholder"
            type="number"
            vid="quinielaAmount"
            ref="quinielaAmount"
            :label="labels.form.quinielaAmount.label"
            :max="99999999999"
            :min="0"
            :rules="'required'"
            :precision="0"
            :formItem="{
              style: {
                marginBottom: 0,
              },
            }"
          />
        </a-col>
        <a-col
          :xs="8"
          :sm="8"
          :md="isSuperpale ? 4 : 5"
          :lg="isSuperpale ? 4 : 5"
        >
          <Input
            v-model="form.paleAmount"
            :placeholder="labels.form.paleAmount.placeholder"
            type="number"
            vid="paleAmount"
            ref="paleAmount"
            :label="labels.form.paleAmount.label"
            :max="99999999999"
            :min="0"
            :rules="'required'"
            :precision="0"
            :formItem="{
              style: {
                marginBottom: 0,
              },
            }"
          />
        </a-col>
        <a-col v-if="isSuperpale" :xs="8" :sm="8" :md="4" :lg="4">
          <Input
            v-model="form.superpaleAmount"
            :placeholder="labels.form.superpaleAmount.placeholder"
            type="number"
            vid="superpaleAmount"
            ref="superpaleAmount"
            :label="labels.form.superpaleAmount.label"
            :max="99999999999"
            :min="0"
            :rules="'required'"
            :precision="0"
            :formItem="{
              style: {
                marginBottom: 0,
              },
            }"
          />
        </a-col>
        <a-col
          :xs="8"
          :sm="8"
          :md="isSuperpale ? 4 : 5"
          :lg="isSuperpale ? 4 : 5"
        >
          <Input
            v-model="form.tripletaAmount"
            :placeholder="labels.form.tripletaAmount.placeholder"
            type="number"
            vid="tripletaAmount"
            ref="tripletaAmount"
            :label="labels.form.tripletaAmount.label"
            :max="99999999999"
            :min="0"
            :rules="'required'"
            :precision="0"
            :formItem="{
              style: {
                marginBottom: 0,
              },
            }"
          />
        </a-col>
        <a-col :xs="24" :sm="24" :md="4" :lg="4">
          <div class="action-buttons">
            <a-button
              type="primary"
              class="btn-add"
              @click="addNumber"
              :loading="submitting"
              block
            >
              <span v-if="!isMobile">{{ labels.form.add }}</span>
              <a-icon type="plus" />
            </a-button>
          </div>
        </a-col>
      </a-row>
    </ValidationObserver>

    <!-- LIST OF UL NUMBERS GENERATED -->
    <a-row v-if="mixes.length" :gutter="[12, 12]" type="flex" justify="start">
      <a-col :span="24">
        <h3 class="add-mix-mixes-title">{{ labels.form.mixesTitle }}</h3>
        <div class="add-mix-mixes-container">
          <div
            v-if="mixesQuinielas.length"
            class="add-mix-mixes-mixes-quinielas"
          >
            <h3 class="add-mix-mixes-list-title">
              {{ labels.form.mixesQuinielas }}
            </h3>
            <ul class="add-mix-mixes-list">
              <li v-for="mix in mixesQuinielas" :key="mix.numbers">
                {{ mix.numbers }}
                <a-icon
                  class="mix-delete"
                  type="delete"
                  :title="labels.form.deleteMix"
                  @click="removeMix(mix)"
                />
              </li>
            </ul>
          </div>
          <div v-if="mixesPales.length" class="add-mix-mixes-mixes-pales">
            <h3 class="add-mix-mixes-list-title">
              {{ labels.form.mixesPales
              }}{{ isSuperpale ? ` / ${labels.form.mixesSuperpales}` : "" }}
            </h3>
            <ul class="add-mix-mixes-list">
              <li v-for="mix in mixesPales" :key="mix.numbers">
                {{ mix.numbers }}
                <a-icon
                  class="mix-delete"
                  type="delete"
                  :title="labels.form.deleteMix"
                  @click="removeMix(mix)"
                />
              </li>
            </ul>
          </div>
          <div
            v-if="mixesTripletas.length"
            class="add-mix-mixes-mixes-tripletas"
          >
            <h3 class="add-mix-mixes-list-title">
              {{ labels.form.mixesTripletas }}
            </h3>
            <ul class="add-mix-mixes-list">
              <li v-for="mix in mixesTripletas" :key="mix.numbers">
                {{ mix.numbers }}
                <a-icon
                  class="mix-delete"
                  type="delete"
                  :title="labels.form.deleteMix"
                  @click="removeMix(mix)"
                />
              </li>
            </ul>
          </div>
        </div>
      </a-col>
    </a-row>

    <a-row v-if="mixes.length" :gutter="[12, 12]" type="flex" justify="start">
      <a-col :span="24">
        <div class="action-buttons">
          <a-button
            type="primary"
            class="btn-add"
            @click="onSubmit"
            :loading="submitting"
          >
            {{ labels.form.addMixes }}
          </a-button>
          <a-button
            type="default"
            class="btn-clean"
            @click="cleanAll"
            :loading="submitting"
            ghost
          >
            {{ labels.form.cleanMixes }}
          </a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Input from "./core/VeeValidateForm/Input.vue";
import * as _ from "lodash";
import { mask } from "vue-the-mask";
import { nextTick } from "vue";
import { PLAY_TYPES, numbersCombinations } from "../utils/utils";

const initialState = {
  numbers: "",
  quinielaAmount: 0,
  paleAmount: 0,
  superpaleAmount: 0,
  tripletaAmount: 0,
};

export default {
  name: "AddMixComponent",
  components: {
    ValidationObserver,
    Input,
  },
  directives: { themask: mask },
  emits: ["submit"],
  props: {
    defaultData: {
      type: Object,
      default: () => ({}),
    },
    labels: {
      type: Object,
      default: () => ({}),
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isSuperpale: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: _.cloneDeep(initialState),
    submitting: false,
    mixes: [],
    numbers: [],
  }),
  created() {
    if (!_.isEmpty(this.defaultData)) {
      Object.assign(this.form, this.defaultData);
    }
    this.focusNumbers();
  },
  computed: {
    mixesQuinielas() {
      return this.mixes.filter((item) => item.type === PLAY_TYPES.QUINIELA);
    },
    mixesPales() {
      return this.mixes.filter((item) =>
        [PLAY_TYPES.PALE, PLAY_TYPES.SUPERPALE].includes(item.type)
      );
    },
    mixesTripletas() {
      return this.mixes.filter((item) => item.type === PLAY_TYPES.TRIPLET);
    },
  },
  methods: {
    normalizeNumbers(numbers) {
      if (!numbers) return numbers;
      return numbers
        .split("-")
        .map((number) => number.trim().padStart(2, "0"))
        .join("-");
    },
    onSubmit() {
      this.$emit("submit", this.mixes);
      this.form = _.cloneDeep(initialState);
      this.numbers = [];
      this.mixes = [];
      this.$refs.addMixObserver.reset();
      this.focusNumbers();
    },
    addNumber() {
      this.$refs.addMixObserver.validate().then((success) => {
        if (success) {
          if (!this.numbers.includes(this.normalizeNumbers(this.form.numbers)))
            this.numbers.push(this.normalizeNumbers(this.form.numbers));

          this.form.numbers = "";
          // RESET NUMBERS FIELD VALIDATION
          this.$refs.addMixObserver.reset();
          this.focusNumbers();
        }
      });
    },
    focusNumbers() {
      nextTick(() => {
        this.$refs.numbers.$el.querySelector("input").focus();
      });
    },
    calculateMixes() {
      const mixes = [];

      // MIX PLAYS BY QUINIELA
      if (this.form.quinielaAmount > 0) {
        _.cloneDeep(this.numbers).forEach((number) => {
          const found = mixes.find((item) => item.numbers === number);
          if (!found) {
            mixes.push({
              numbers: number,
              amount: this.form.quinielaAmount,
              type: PLAY_TYPES.QUINIELA,
            });
          }
        });
      }

      // MIX PLAYS BY PAIRS
      if (
        this.numbers.length >= 2 &&
        (this.form.paleAmount > 0 || this.form.superpaleAmount > 0)
      ) {
        _.cloneDeep(this.numbers).forEach((number, index) => {
          _.cloneDeep(this.numbers).forEach((number2, index2) => {
            const found = mixes.find(
              (item) =>
                item.numbers === `${number}-${number2}` ||
                item.numbers === `${number2}-${number}`
            );
            if (!found && index !== index2) {
              mixes.push({
                numbers: `${number}-${number2}`,
                amount: this.form.paleAmount,
                amountSuperpale: this.form.superpaleAmount,
                type:
                  this.form.paleAmount === 0
                    ? PLAY_TYPES.SUPERPALE
                    : PLAY_TYPES.PALE,
              });
            }
          });
        });
      }

      // MIX PLAYS BY TRIPLETS
      if (this.numbers.length >= 3 && this.form.tripletaAmount > 0) {
        _.cloneDeep(this.numbers).forEach((number, index) => {
          _.cloneDeep(this.numbers).forEach((number2, index2) => {
            _.cloneDeep(this.numbers).forEach((number3, index3) => {
              const found = mixes.find(
                (item) =>
                  item.numbers === `${number}-${number2}-${number3}` ||
                  item.numbers === `${number}-${number3}-${number2}` ||
                  item.numbers === `${number2}-${number}-${number3}` ||
                  item.numbers === `${number2}-${number3}-${number}` ||
                  item.numbers === `${number3}-${number}-${number2}` ||
                  item.numbers === `${number3}-${number2}-${number}`
              );
              if (!found && index !== index2 && index !== index3) {
                mixes.push({
                  numbers: `${number}-${number2}-${number3}`,
                  amount: this.form.tripletaAmount,
                  type: PLAY_TYPES.TRIPLET,
                });
              }
            });
          });
        });
      }

      // UNIQUE MIXES BY TYPE AND NUMBERS
      this.mixes = mixes.reduce((acc, current) => {
        const combinations = numbersCombinations(current.numbers);
        const foundIndex = acc.findIndex(
          (item) =>
            item.type === current.type && combinations.includes(item.numbers)
        );
        if (foundIndex === -1) {
          acc.push(current);
        }
        return acc;
      }, []);
    },
    cleanAll() {
      this.numbers = [];
      this.mixes = [];
    },
    removeMix(mix) {
      this.mixes = this.mixes.filter((item) => item.numbers !== mix.numbers);
    },
  },
  watch: {
    numbers: {
      handler(val = []) {
        if (val.length) {
          this.calculateMixes();
        } else {
          this.mixes = [];
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.add-mix-component {
  padding: 15px 15px 20px 15px !important;
  background-color: color(c-secondary);
  border: 1px solid color(c-secondary);
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  .form-title {
    font-size: 18px;
    font-weight: 600;
    color: color(--white);
    margin-bottom: 10px;
    display: block;
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    padding-bottom: 5px;
    margin-top: 0;
    .btn-add {
      margin-top: 1.5rem;
      border-color: color(c-hover);
      background-color: color(c-primary);
      color: color(c-hover);
      &:hover {
        border-color: color(c-hover);
        background-color: color(c-primary);
        color: color(c-hover);
      }
    }
    .btn-clean {
      margin-top: 1.5rem;
      &:active,
      &:focus,
      &:hover {
        color: color(danger);
        border-color: color(danger);
        background-color: color(c-primary);
      }
    }
  }
  .add-mix-mixes {
    &-title {
      font-size: 18px;
      font-weight: 600;
      color: color(--white);
      margin-bottom: 10px;
      margin-top: 20px;
      display: block;
    }
    &-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    &-list {
      &-title {
        font-size: 18px;
        font-weight: 600;
        color: color(--white);
        margin-bottom: 10px;
        margin-top: 20px;
        display: block;
      }
      .mix-delete {
        margin-left: 10px;
        cursor: pointer;
        &:hover {
          color: color(danger);
        }
      }
    }
  }
}
</style>
