<template>
  <span v-bind="$attrs">
    <slot :days="days" :hours="hours" :minutes="minutes" :seconds="seconds" />
  </span>
</template>
<script>
export default {
  name: "CountdownComponent",
  props: {
    time: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      timeLeft: this.time,
      interval: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      this.interval = setInterval(() => {
        const modValue = 1000 * 60 * 60 * 24;
        this.timeLeft -= 1000;
        this.days = Math.floor(this.timeLeft / modValue);
        this.hours = Math.floor((this.timeLeft % modValue) / (1000 * 60 * 60));
        this.minutes = Math.floor(
          (this.timeLeft % (1000 * 60 * 60)) / (1000 * 60)
        );
        this.seconds = Math.floor((this.timeLeft % (1000 * 60)) / 1000);
      }, 1000);
    },
  },
  watch: {
    timeLeft() {
      if (this.timeLeft <= 0) {
        clearInterval(this.interval);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
