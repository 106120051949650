<template>
  <a-modal
    v-if="show"
    v-model="show"
    :footer="null"
    :width="width"
    @cancel="close"
    :closable="false"
    class="re-buy-ticket-modal"
    :maskClosable="false"
  >
    <template #title>
      <div class="title-text">
        <a-icon :type="icon" />
        <span>{{ title }}</span>
      </div>
    </template>

    <ValidationObserver ref="rebuy-observer">
      <a-form
        :label-width="80"
        slot-scope="{ validate }"
        @submit.prevent="validate().then(nextStep)"
      >
        <!-- SELECT LOTTERIES -->
        <a-row v-if="step === 0" :gutter="[16, 16]">
          <a-col :span="24">
            <SelectPagination
              v-model="form.lotteries"
              ref="lotteries"
              :fetchService="fetchLotteries"
              :label="labels.rebuyTicketModal.form.lotteries.label"
              labelKey="name"
              valueKey="id"
              mode="multiple"
              :placeholder="labels.rebuyTicketModal.form.lotteries.placeholder"
              size="large"
              class="select-lotteries"
              rules="required"
              :disabledDefaultFetchOptions="false"
            >
              <template #renderOption="{ data }">
                <div
                  :style="{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                  }"
                >
                  <span class="avatar" :style="{ width: '50px' }">
                    <a-avatar
                      :style="{
                        backgroundColor: '#3a3a3a',
                        verticalAlign: 'middle',
                      }"
                      v-bind="data.logo ? { src: data.logo } : {}"
                      >{{ data.abbreviated }}</a-avatar
                    >
                  </span>
                  <span class="text-container">
                    <div :style="{ margin: 0 }">{{ data.name }}</div>
                    <small class="text-muted">{{
                      labels.lotteryCLoseTimeLabel(data.today_end_time)
                    }}</small>
                  </span>
                </div>
              </template>
            </SelectPagination>
          </a-col>
        </a-row>

        <!-- CONFIRM PLAYS AND ADD -->
        <a-row
          v-if="form.details.length && step === 1"
          :gutter="[24, 24]"
          type="flex"
          justify="start"
        >
          <a-col :span="24" :style="{ textAlign: 'right' }">
            <!-- TITLE -->
            <span class="form-title">
              {{ labels.rebuyTicketModal.form.confirmPlaysAndAdd }}
            </span>

            <!-- PLAYS LIST -->
            <a-table
              v-if="dataSource.length"
              :dataSource="dataSource"
              :pagination="false"
              :columns="columns"
              :rowKey="'key'"
              :size="'small'"
              :bordered="false"
              :class="'cart-component-table'"
              :expandIcon="expandIcon"
              :rowClassName="() => `table-row`"
              :expandRowByClick="false"
              :expandedRowKeys="dataSource.map((item) => item.key)"
            />
          </a-col>
        </a-row>

        <!-- FOOTER BUTTONS -->
        <a-row :gutter="[16, 16]" type="flex" justify="end">
          <a-col
            :xs="24"
            :sm="24"
            :md="12"
            :style="{ textAlign: isMobile ? 'center' : 'right' }"
          >
            <template v-if="isMobile">
              <a-button
                v-if="step === 0"
                type="primary"
                size="large"
                class="btn-next"
                @click="validate().then(nextStep)"
                v-bind="isMobile ? { block: true } : {}"
              >
                {{ labels.rebuyTicketModal.next }}
              </a-button>
              <a-button
                v-if="step === 1"
                type="primary"
                size="large"
                class="btn-add"
                @click="validate().then(addPlays)"
                v-bind="isMobile ? { block: true } : {}"
              >
                {{ labels.rebuyTicketModal.addPlays }}
                <a-icon type="plus" />
              </a-button>
              <a-button
                v-if="step === 0"
                type="danger"
                size="large"
                @click="close"
                class="btn-cancel"
                ghost
                v-bind="isMobile ? { block: true } : {}"
              >
                {{ labels.rebuyTicketModal.cancel }}
              </a-button>
              <a-button
                v-if="step === 1"
                type="primary"
                size="large"
                class="btn-prev"
                @click="prevStep"
                ghost
                v-bind="isMobile ? { block: true } : {}"
              >
                {{ labels.rebuyTicketModal.prev }}
              </a-button>
            </template>
            <template v-else>
              <a-button
                v-if="step === 0"
                type="danger"
                size="large"
                @click="close"
                class="btn-cancel"
                ghost
                v-bind="isMobile ? { block: true } : {}"
              >
                {{ labels.rebuyTicketModal.cancel }}
              </a-button>
              <a-button
                v-if="step === 1"
                type="primary"
                size="large"
                class="btn-prev"
                @click="prevStep"
                ghost
                v-bind="isMobile ? { block: true } : {}"
              >
                {{ labels.rebuyTicketModal.prev }}
              </a-button>
              <a-button
                v-if="step === 0"
                type="primary"
                size="large"
                class="btn-next"
                @click="validate().then(nextStep)"
                v-bind="isMobile ? { block: true } : {}"
              >
                {{ labels.rebuyTicketModal.next }}
              </a-button>
              <a-button
                v-if="step === 1"
                type="primary"
                size="large"
                class="btn-add"
                @click="validate().then(addPlays)"
                v-bind="isMobile ? { block: true } : {}"
              >
                {{ labels.rebuyTicketModal.addPlays }}
                <a-icon type="plus" />
              </a-button>
            </template>
          </a-col>
        </a-row>
      </a-form>
    </ValidationObserver>
  </a-modal>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import SelectPagination from "@/components/core/SelectPagination/index.vue";
import { mapActions, mapState } from "vuex";
import EventBus, { ListEvents } from "../event";
import { PLAY_TYPES, generateUniqueID } from "../utils/utils";
import _ from "lodash";
import accounting from "accounting";

const defaultFormState = {
  lotteries: [],
  details: [],
  sharedBy: null,
  sharedTicket: null,
};

export default {
  name: "ReBuyTicketModal",
  emits: ["submit", "cancel"],
  components: {
    ValidationObserver,
    SelectPagination,
  },
  props: {
    icon: {
      type: String,
      default: "exclamation-circle",
    },
    defaultData: {
      type: Object,
      default: () => null,
    },
    labels: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
      step: 0,
      form: _.cloneDeep(defaultFormState),
    };
  },
  created() {
    EventBus.$on(ListEvents.open_rebuy_ticket_modal, this.open);
    EventBus.$on(ListEvents.close_rebuy_ticket_modal, this.close);
  },
  beforeDestroy() {
    EventBus.$off(ListEvents.open_rebuy_ticket_modal);
    EventBus.$off(ListEvents.close_rebuy_ticket_modal);
  },
  computed: {
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 768;
    },
    width() {
      return this.screenWidth < 992 ? "96%" : 992;
    },
    columns() {
      return [
        {
          title: this.labels.table.play,
          dataIndex: "type",
          key: "type",
          width: "80px",
          customRender: (text, record) => {
            if (record?.isLottery) {
              const lotteryName = record.secondLottery?.id
                ? `${record.lottery.name} - ${record.secondLottery.name}`
                : record.lottery.name;
              return {
                children: <span class="text-hover">{lotteryName}</span>,
                attrs: {
                  colSpan: 4,
                },
              };
            }
            return this.$options.filters.ucfirst(text);
          },
        },
        {
          title: this.labels.table.numbers,
          dataIndex: "numbers",
          key: "numbers",
          width: "60px",
          align: "center",
          customRender: (numbers, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return numbers;
          },
        },
        {
          title: this.labels.table.amount,
          dataIndex: "amount",
          key: "amount",
          width: "60px",
          align: "right",
          customRender: (amount, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return accounting.formatNumber(amount, 2, ",", ".");
          },
        },
        {
          title: "",
          dataIndex: "action",
          key: "action",
          width: "20px",
          align: "center",
          customRender: (_, record) => {
            return record.isLottery ? (
              {
                attrs: {
                  colSpan: 0,
                },
              }
            ) : (
              <div>
                <a-tooltip
                  overlayClassName={"custom-tooltip-danger"}
                  title={this.labels.table.action.remove}
                  placement={"right"}
                >
                  <a-button
                    disabled={this.deleting}
                    type="link"
                    size="small"
                    class="btn-remove"
                    onClick={() => this.handleRemove(record)}
                    icon="delete"
                    style={{ color: "#f5222d" }}
                  />
                </a-tooltip>
              </div>
            );
          },
        },
      ];
    },
    dataSource() {
      return Object.values(
        _.cloneDeep(this.form.details).reduce((acc, val) => {
          const lottery = val.lottery;
          const isSuperpale = val.type === PLAY_TYPES.SUPERPALE;
          const secondLottery = val.secondLottery || null;
          const key = isSuperpale
            ? `${lottery.id}-${secondLottery.id}`
            : lottery.id;
          if (!acc[key]) {
            acc[key] = {
              key: this.uuid(),
              lottery,
              ...(isSuperpale ? { secondLottery } : {}),
              isLottery: true,
              children: [],
            };
          }
          acc[key].children.push(val);
          return acc;
        }, {})
      );
    },
  },
  methods: {
    ...mapActions("buy", ["fetchLotteries"]),
    uuid() {
      return generateUniqueID();
    },
    close() {
      this.$emit("cancel");
      this.show = false;
    },
    open() {
      if (_.cloneDeep(this.defaultData)?.details?.length) this.show = true;
    },
    expandIcon({ expandable }) {
      return expandable ? "" : " ";
    },
    async handleRemove(record) {
      const details = _.cloneDeep(this.form.details);
      const index = details.findIndex(
        (item) =>
          item.lottery.id === record.lottery.id &&
          item.numbers === record.numbers &&
          item.type === record.type &&
          item.secondLottery?.id === record.secondLottery?.id
      );
      if (index !== -1) {
        details.splice(index, 1);
        this.form.details = details;
      }
    },
    async handleAddPlay(data, isSuperpale = false) {
      try {
        const lotteryCount = this.form.lotteries.length;
        let details = [];

        if (isSuperpale) {
          if (lotteryCount < 2) {
            this.$notification.error({
              message: this.labels.form.lotteries.label,
              description: this.labels.form.lotteries.min(2),
            });
            return;
          }

          // LOTTERIES MIX
          this.form.lotteries.forEach((lottery, index) => {
            this.form.lotteries.forEach((secondLottery, index2) => {
              const found = details.find(
                (item) =>
                  (item.lottery.id === lottery.id &&
                    item.secondLottery.id === secondLottery.id) ||
                  (item.lottery.id === secondLottery.id &&
                    item.secondLottery.id === lottery.id)
              );
              if (!found && index !== index2) {
                details.push({
                  lottery: lottery,
                  secondLottery: secondLottery,
                });
              }
            });
          });

          // GENERATE PLAYS
          details = details.map((item) => ({
            ...data,
            lottery: {
              id: item.lottery.id,
              name: item.lottery.name,
              abbreviated: item.lottery.abbreviated,
              today_end_time: item.lottery.today_end_time,
            },
            secondLottery: {
              id: item.secondLottery.id,
              name: item.secondLottery.name,
              abbreviated: item.secondLottery.abbreviated,
              today_end_time: item.secondLottery.today_end_time,
            },
          }));
        } else {
          // MIX PLAYS
          this.form.lotteries.forEach((lottery) => {
            const found = details.find(
              (item) =>
                !item.secondLottery &&
                item.numbers === data.numbers &&
                item.type === data.type &&
                item.lottery.id === lottery.id
            );
            if (!found) {
              details.push({
                ...data,
                lottery: {
                  id: lottery.id,
                  name: lottery.name,
                  abbreviated: lottery.abbreviated,
                  today_end_time: lottery.today_end_time,
                },
              });
            }
          });
        }

        // ADD PLAYS IF NOT EXIST
        details.forEach((play) => {
          const found = this.form.details.find(
            (item) =>
              item.lottery.id === play.lottery.id &&
              item.numbers === play.numbers &&
              item.type === play.type &&
              item.secondLottery?.id === play.secondLottery?.id
          );
          if (!found) {
            this.form.details.push({ ...play, key: this.uuid() });
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    prevStep() {
      this.step = 0;
    },
    nextStep() {
      try {
        if (this.step === 0) {
          const { details = [] } = this.defaultData;
          const plays = _.uniqBy(
            _.cloneDeep(details).map(({ amount, numbers, type }) => {
              return {
                amount,
                numbers,
                type,
                key: this.uuid(),
              };
            }),
            (play) => `${play.numbers}-${play.type}`
          );
          // ADD PLAYS
          plays.forEach((play) => {
            this.handleAddPlay(play, this.defaultData.isSuperpale);
          });
          this.form.sharedBy = this.defaultData.userId;
          this.form.sharedTicket = this.defaultData.number;
        }

        this.step = 1;
      } catch (error) {
        console.error(error);
      }
    },
    addPlays() {
      this.$emit("submit", _.cloneDeep(this.form));
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.re-buy-ticket-modal {
  ::v-deep .ant-modal-content {
    background-color: color(c-secondary);
    border-radius: 0;
    border: 1px solid color(c-hover-wait);
    box-shadow: 0 1px 3px 3px color(c-hover-wait);
  }
  ::v-deep .ant-modal-header {
    background-color: transparent;
    border-color: color(c-hover);
    .title-text {
      color: color(--white);
      font-size: 18px;
      .anticon {
        margin-right: 10px;
        color: color(c-hover);
      }
    }
  }
  ::v-deep .ant-select-selection__choice__content {
    .avatar {
      display: none;
    }
    .text-container {
      width: 100%;
      .text-muted {
        display: none;
      }
    }
  }
  ::v-deep .ant-form-item-label label {
    font-weight: 500;
    font-size: 18px;
    color: color(--white) !important;
  }
  ::v-deep .ant-select-lg .ant-select-selection--multiple {
    background-color: color(c-secondary);
    .ant-select-selection__choice {
      background-color: color(c-primary);
      border-color: color(muted-light) !important;
      color: color(--white);
      .anticon-close {
        color: color(--white);
      }
    }
  }
  .btn-cancel {
    margin-top: 1.5rem;
    color: color(c-border);
    border-color: color(c-border);
    &:active,
    &:focus,
    &:hover {
      color: color(danger);
      border-color: color(danger);
      background-color: color(c-primary);
    }
  }
  .btn-prev {
    margin-top: 1.5rem;
    color: color(c-border);
    border-color: color(c-border);
    &:active,
    &:focus,
    &:hover {
      color: color(c-border);
      border-color: color(c-border);
    }
  }
  .btn-add,
  .btn-next {
    margin-top: 1.5rem;
    margin-left: 10px;
    color: color(c-hover);
    border-color: color(c-hover);
    background-color: color(c-primary);
    &:active,
    &:focus,
    &:hover {
      color: color(c-primary);
      border-color: color(c-hover);
      background-color: color(c-hover);
    }
    @media screen and (max-width: $screen-mobile) {
      margin-left: 0;
    }
  }
}
</style>
<style lang="scss">
@import "~@/assets/scss/variable";
.ant-table {
  &:hover {
    transition: 0.5s ease;
    background: unset;
  }
  tr.ant-table-row:hover {
    transition: 0.5s ease;
    background: rgba(0, 0, 0, 0.25) !important;
  }
  tr:nth-child(2n) {
    transition: 0.5s ease;
    background: rgba(0, 0, 0, 0.2) !important;
  }
  tr.table-row-disabled,
  tr.table-row-disabled:hover {
    color: color(c-primary);
    background: color(danger-light) !important;
    font-weight: 600;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    transition: 0.5s ease;
    background: none !important;
  }
  &-tbody,
  &-thead {
    color: color(--white) !important;
    tr {
      th {
        color: color(--white) !important;
      }
    }
  }
  &-footer {
    background: transparent;
  }
  @media screen and (max-width: $screen-mobile) {
    &-small {
      border: none;
    }
    &-body {
      margin: 0 1px;
    }
  }
}
</style>
